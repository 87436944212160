div#amplitude-left{
  padding: 0px;
  border-right: 1px solid #CFD8DC;
  width: 50%;
  display: flex;
  flex-direction: column;

  img{
    width: 100%;
  }

  div#player-left-bottom{
    flex: 1;
    background-color: #F1F1F1;
    padding: 20px 10px;

    div#volume-container:after{
      content: "";
      display: table;
      clear: both;
    }
  }
}

/* Small only */
@media screen and (max-width: 39.9375em) {
	div#amplitude-player{
		div#amplitude-left{
      width: 100%;

			img[amplitude-song-info="cover_art_url"]{
				width: auto;
				height: auto;
			}
		}
	}
}
