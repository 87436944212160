.footer {
  height: 50px;
  margin-top: -50px;
  background: #000000;
  font-family: 'Roboto Mono', monospace;
  text-align: center;
  line-height: 50px;
  color: #cccccc;
  font-size: .7em;
}

.footer a {
  color: #cccccc;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  .footer{
    line-height: 1.5;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .footer{
    line-height: 1.5;
  }
}