@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

body {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  line-height: 1.5;
}

.content {
  min-height: 100%;
}

.content-inside {
  padding: 20px 20px 50px;
}

.album_url {
  line-height: 3;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  .content-inside {
    padding: 0 0 50px 0;
  }

  .album_url {
    line-height: 1.5;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .content-inside {
    padding: 0 0 50px 0;
  }

  .album_url {
    line-height: 1.5;
  }
}