div#amplitude-player{
  background: #FFFFFF;
  box-shadow: 0 2px 12px 8px rgba(0,0,0,0.10);
  margin: 20px auto;
  display: flex;
  max-width: 900px;
  z-index: 9;
  position: relative;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  div#amplitude-player{
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  div#amplitude-player{
    max-height: 715px;
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
  } 
}

/* Large and up */
@media screen and (min-width: 64em) {
 div#amplitude-player{
   max-height: 715px;
 } 
}